<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="用户id关联{zb_user.id}" prop="userId">-->
<!--                <a-input v-model="queryParam.userId" placeholder="请输入用户id关联{zb_user.id}" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="规格id" prop="skuId">-->
<!--                <a-input v-model="queryParam.skuId" placeholder="请输入规格id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="Flavor Name" prop="name">
                <a-input v-model="queryParam.name" placeholder="" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="Usage Effects Tags" prop="tags">-->
<!--                <a-input v-model="queryParam.tags" placeholder="" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:skuFlavor:add']">-->
<!--          <a-icon type="plus" />Add-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:skuFlavor:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:skuFlavor:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:skuFlavor:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <a-row :gutter="[16,16]">
        <a-col :span="6" v-hasPermi="['cigarette:skuFlavor:add']">
          <div class="goodsDateAdd" @click="$refs.createForm.handleAdd(skuId)">
            <a-icon type="plus" class="goodsDateAdd-icon" style="font-size: 50px;" />
            <div class="goodsDateAdd-title">Create Flavor</div>
          </div>
        </a-col>
        <a-col :span="6" v-for="(item,index) in list">
          <div class="goodsDateInfo" :style="[{
            background:item.status === 0 ? '#f8f8f8':'#fff'
          }]">
            <div class="goodsDateInfo-absolute">
              <a-icon type="play-circle" theme="twoTone" v-hasPermi="['cigarette:skuCurves:status']" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />
              <a-icon type="pause-circle" theme="twoTone" v-hasPermi="['cigarette:skuCurves:status']" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />
              <a-icon type="eye" v-hasPermi="['cigarette:skuFlavor:detail']" @click="$refs.createForm.handleShow(item, undefined)" theme="twoTone" style="font-size: 28px;margin-left: 5px" />
              <a-icon type="edit"  v-hasPermi="['cigarette:skuFlavor:edit']" @click="$refs.createForm.handleUpdate(item, undefined)" theme="twoTone" style="font-size: 28px;margin-left: 5px" />
            </div>
            <div class="goodsDateInfo-GoodsDate">
              <div class="goodsDateInfo-GoodsDate-flex">
                <div class="goodsDateInfo-GoodsDate-flex-img-flex">
                  <img class="goodsDateInfo-GoodsDate-flex-img" :src="item.url" />
                  <div class="goodsDateInfo-GoodsDate-flex-img-flex-right" :id="'chart-' + item.id">
                  </div>
                </div>

                <div class="goodsDateInfo-GoodsDate-flex-name">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
<!--      <a-empty :description="false" style="margin-top: 100px;margin-bottom: 100px" v-if="list.length === 0" />-->
      <!-- 数据展示   :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
<!--      <a-table-->
<!--        :loading="loading"-->
<!--        :size="tableSize"-->
<!--        rowKey="id"-->
<!--        :columns="columns"-->
<!--        :data-source="list"-->
<!--        :pagination="false"-->
<!--        :bordered="tableBordered"-->
<!--      >-->
<!--        <span slot="terpenes" slot-scope="text, record">-->
<!--          <a-tag color="blue" v-for="(item,index) in record.ingredientsType" style="margin-bottom: 5px" :key="index">-->
<!--            {{item.publicName}}:{{ item.flavorValue }}%-->
<!--          </a-tag>-->
<!--         <a-tag color="green"  v-for="(item,index) in record.terpenesType" style="margin-bottom: 5px" :key="index">-->
<!--        {{item.publicName}}:{{ item.flavorValue }}%-->
<!--      </a-tag>-->
<!--        </span>-->

<!--        <span slot="tags" slot-scope="text, record">-->
<!--          <a-tag v-for="(item,index) in record.publicConfigList" style="margin-bottom: 5px" :key="index">-->
<!--            {{ item.name}}-->
<!--          </a-tag>-->

<!--        </span>-->
<!--        <span slot="createTime" slot-scope="text, record">-->
<!--          {{ parseTime(record.createTime) }}-->
<!--        </span>-->
<!--        <span slot="operation" slot-scope="text, record">-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:skuFlavor:detail']">-->
<!--            <a-icon type="eye" />Details-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:skuFlavor:edit']" />-->

<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:skuFlavor:edit']">-->
<!--            <a-icon type="edit" />Modify-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:skuCurves:status']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:skuCurves:status']">-->
<!--            <a-icon type="audit" />Change-->
<!--          </a>-->
<!--        </span>-->
<!--      </a-table>-->
      <!-- 分页 show-size-changer -->
      <a-pagination
        class="ant-table-pagination"
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSkuFlavor,listSkuFlavor,pageAdminSkuFlavor, delSkuFlavor,updateSkuFlavor,editStatus } from '@/api/cigarette/skuFlavor'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import * as echarts from "echarts";
export default {
  name: 'SkuFlavor',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      skuId:'',
      // 查询参数
      queryParam: {
        userId: null,
        skuId: null,
        name: null,
        url: null,
        tags: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'Flavor ID',
          dataIndex: 'id',
          align: 'center'
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '用户id关联{zb_user.id}',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '规格id',
        //   dataIndex: 'skuId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Flavor Name',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: 'Brand',
          dataIndex: 'brandName',
          align: 'center'
        },
        {
          title: 'Flavor Picture',
          dataIndex: 'url',
          align: 'center'
        },
        {
          title: 'Usage Effects Tags',
          dataIndex: 'tags',
          align: 'center',
          scopedSlots: { customRender: 'tags' },
        },

        {
          title: 'Ingredients And Terpenes',
          dataIndex: 'tags',
          align: 'center',
          scopedSlots: { customRender: 'terpenes' },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          align: 'center',
          customRender(t,r) {
            if(r.type == 1) {
              return 'Enable'
            } else if(r.status == 2) {
              return 'Draft'
            } else {
              return 'Disable'
            }
          }
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.skuId = this.$route.query.skuId || '';
    this.queryParam.skuId = this.$route.query.skuId || '';
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    changeStatus(item,status) {

      let that = this;
      this.$confirm({
        title:status == 1 ?  'Are sure to enble this Flavor?' : 'Are sure to Disable this Flavor?',
        content: "",
        icon:"exclamation-circle",
        okText: "Yes",
        cancelText: "No",
        onOk () {
          let newItemData = JSON.parse(JSON.stringify(item));
          newItemData.status = status;
          return editStatus(newItemData).then(res => {
            if(res.success) {
              that.$set(item,'status',status);
            }
          })
          // return updateBrand(updateBrandData)
          //   .then(() => {
          //     that.getList()
          //   })
        },
        onCancel () {
        }
      })

    },
    renderChart(item) {
      let newData = [...item.ingredientsType,...item.terpenesType].map(i => ({
        name:i.publicName,
        value:i.flavorValue
      }));
      const chartDom = document.getElementById(`chart-${item.id}`);
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        legend: {

          orient: 'vertical',
          bottom:10,
          type: 'scroll',
          height:50
        },

        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data:newData
          }
        ]
      };
      myChart.setOption(option);
    },
    /** 查询新-规格口味列表 */
    getList () {
      this.loading = true
      pageAdminSkuFlavor(this.queryParam).then(response => {
        this.list = response.data.records
        setTimeout(() => {
          this.list.forEach((item, index) => {
            this.renderChart(item);
          });
        },1000)
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        skuId: undefined,
        name: undefined,
        url: undefined,
        tags: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSkuFlavor(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/goods-sku-flavor/export', {
            ...that.queryParam
          }, `新-规格口味_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goodsDateAdd {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}
.goodsDateAdd:hover {
  border: 1px solid blue;

  .goodsDateAdd-icon {
    color:blue;
  }

  .goodsDateAdd-title {
    color: blue;
  }
}
.goodsDateInfo {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}

.goodsDateInfo-GoodsDate-flex-img {
  height: 240px;
  width: 40%;
}

.goodsDateInfo-GoodsDate-flex-img-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 240px;
  width: 90%;
}

.goodsDateInfo-GoodsDate-flex-img-flex-right {
  width: 55%;
  height: 240px;
  //background: #f8f8f8;
}
.goodsDateInfo-GoodsDate-flex-name {
  width: 80%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goodsDateInfo-absolute {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  z-index: 2;
}
.goodsDateInfo-GoodsDate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.goodsDateInfo-GoodsDate-flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.goodsDateInfo-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  display: none;
}

.goodsDateInfo-bottom-flex {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}
.goodsDateInfo:hover {
  border: 1px solid blue;
  .goodsDateInfo-absolute {
    display: block;
  }

  .goodsDateInfo-bottom {
    display: block;
  }
}
</style>
